import React, { useEffect, useState } from "react";
import "../../src/Styles/workdetail.scss";
import { FaSearchLocation } from "react-icons/fa";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import nophoto from "../assets/New folder/no-photo.webp";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
const Workdetail = ({ close, taluka }) => {
  const [villageList, setVillagelist] = useState([]);
  const [data, setData] = useState([]);
  // const { taluka } = useParams();
  const fetchVillage = async () => {
    try {
      const response = await axios.get(
        `https://backend.sambhajipatilnilangekar.com/api/villageworks/taluka/${taluka}/`
      );
      setVillagelist(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchWorkDetail = async (id) => {
    try {
      const response = await axios.get(
        `https://backend.sambhajipatilnilangekar.com/api/villageworks/${id}/`
      );
      setData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const clickHandler = (myid) => {
    fetchWorkDetail(myid);
  };

  const [search, setSearch] = useState("");
  const [popup, setPopup] = useState(false);
  useEffect(() => {
    fetchVillage();
  }, []);

  useEffect(() => {
    if (villageList.length > 0) {
      const firstVillageId = villageList[0].id;
      fetchWorkDetail(firstVillageId);
    }
  }, [villageList]);

  console.log(data);

  function extractYear(dateString) {
    const year = dateString.split("-")[0];
    return year;
  }

  function formatToIndianCurrency(number) {
    let numStr = number.toString();
    let [integerPart, decimalPart] = numStr.split(".");
    let lastThreeDigits = integerPart.slice(-3);
    let otherDigits = integerPart.slice(0, -3);
    if (otherDigits !== "") {
      otherDigits = otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",");
    }
    let formattedNumber =
      otherDigits + (otherDigits ? "," : "") + lastThreeDigits;
    return decimalPart ? `${formattedNumber}.${decimalPart}` : formattedNumber;
  }
  return (
    <>
      <div className="workdetail-parent parent">
        <div className="workdetail-cont cont">
          <div className="left">
            <div className="input">
              <input
                type="text"
                placeholder="गावाच्या नावाने शोधा"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
            <div className="village-list">
              {villageList
                .filter((item) =>
                  item.village_name.toLowerCase().includes(search.toLowerCase())
                )
                .map((item, index) => (
                  <div
                    className="village"
                    key={index}
                    onClick={() => clickHandler(item.id)}
                  >
                    <p key={index}>{item.village_name}</p>
                    <p className="work-det-desc">
                      {item.work_name}
                      {item.start_date || item.end_date
                        ? `(${
                            item.start_date ? extractYear(item.start_date) : ""
                          }${item.start_date && item.end_date ? " - " : ""}${
                            item.end_date ? extractYear(item.end_date) : ""
                          })`
                        : null}
                    </p>
                  </div>
                ))}
            </div>
          </div>
          <div className="right">
            <div className="top-heading">
              <h3 className="title">
                <span> {data.village_name}</span> येथे झालेली विकासकामे
              </h3>
              <button onClick={close} className="btnz">
                नकाशा पहा
              </button>
            </div>
            <div className="details-village">
              {data.images && data.images.length > 0 ? (
                <Swiper
                  pagination={{
                    clickable: true,
                  }}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true,
                  }}
                  modules={[Pagination, Navigation, Autoplay]}
                  className="village-image-swiper"
                >
                  {data.images.map((item, index) => (
                    <SwiperSlide
                      className="work-image bg-img-cover"
                      style={{ background: `url(${item.image})` }}
                      key={index}
                    ></SwiperSlide>
                  ))}
                </Swiper>
              ) : (
                <div
                  className="work-image placeholder-no-photo"
                  style={{ background: `url(${nophoto})` }}
                ></div>
              )}

              <div className="details-work-details">
                {data.start_date && (
                  <span className="btn-active2">
                    दिनांक : {extractYear(data.start_date)}
                    {data.end_date && `- ${extractYear(data.end_date)}`}
                  </span>
                )}
                {data.sector_name && (
                  <span className="btn-active2">
                    क्षेत्र : {data.sector_name}{" "}
                  </span>
                )}
                {data.work_budget && (
                  <span className="btn-active2">
                    रक्कम : ₹{" "}
                    {data.work_budget &&
                      formatToIndianCurrency(data.work_budget)}{" "}
                  </span>
                )}
              </div>
              <p>{data.work_desc}</p>
            </div>
          </div>
        </div>
      </div>

      {popup && (
        <div className="popup" onClick={() => setPopup(false)}>
          <div className="left">
            <div className="input">
              <input
                type="text"
                placeholder="search the village"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
            <div className="village-list">
              {villageList
                .filter((item) =>
                  item.title.toLowerCase().includes(search.toLowerCase())
                )
                .map((item, index) => (
                  <div className="village">
                    <p key={index} onClick={() => setPopup(false)}>
                      {item.title}
                    </p>
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Workdetail;
