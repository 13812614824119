import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import laturImg from "../assets/New folder/map.png"; // Ensure path is correct
import "../Styles/laturmap.scss";
import { PiMapPinSimpleFill } from "react-icons/pi";
import Workdetail from "./Workdetail";

const LaturMap = () => {
  const [toggle, setToggle] = useState(false);
  const [taluka,setTaluka] = useState("निलंगा")
  const closeModel=()=>
  {
    setToggle(false)
  }

  const shirurHandler=()=>
  {
    setTaluka("शिरूरअनंतपाळ");
    setToggle(true);
  }
  const deoniHandler=()=>
  {
    setTaluka("देवणी");
    setToggle(true);
  }
  const nilangaHandler=()=>
  {
    setTaluka("निलंगा");
    setToggle(true);
  }
  return (
    <>
      {!toggle ? (
        <div className="laturmap parent">
          <div className="laturmap-cont cont">
            <img src={laturImg} alt="latur map" useMap="#villages" />
            <div  className="lbox box1" onClick={shirurHandler}>
              <PiMapPinSimpleFill />
            </div>
            <div  className="lbox  box2" onClick={deoniHandler}>
              <PiMapPinSimpleFill />
            </div>
            <div  className="lbox box3" onClick={nilangaHandler}>
              <PiMapPinSimpleFill />
            </div>
          </div>
        </div>
      ) : (
        <Workdetail close={closeModel} taluka={taluka}/>
      )}
    </>
  );
};

export default LaturMap;
