import React, { useState } from 'react';
import "../Styles/manifesto.scss"; // Adjust the path as needed
import { FaXTwitter } from "react-icons/fa6";
import { FaFacebookF } from "react-icons/fa";
import { IoLogoYoutube } from "react-icons/io5";
import { AiFillInstagram } from "react-icons/ai";

function Manifesto(props) {
    const [isIframeOpen, setIframeOpen] = useState(false);
    const [selectedBook, setSelectedBook] = useState('');

    const handleBookClick = (book) => {
        setSelectedBook(book);
        setIframeOpen(true);
    };

    const closeIframe = () => {
        setIframeOpen(false);
        setSelectedBook('');
    };

    const handleDownload = (url) => {
        const a = document.createElement('a');
        a.href = url; // URL of the PDF
        a.target = '_blank'; // Opens the link in a new tab
        document.body.appendChild(a);
        a.click(); // Trigger the open in new tab
        document.body.removeChild(a); // Clean up
    };

    const pdfUrl = 'https://www.flipbookpdf.net/web/files/uploads/1ded29580fb14dce08301cb59244766dd8fd2976202410.pdf';

    return (
        <>
        {props.data.map((data) => {
        return (
          <>
        <div className="manifesto parent">
            <div className="manifesto-cont cont">
                <div className="mcontent">
                    <div className="card mleft">
                        <h2>{data.m_english}</h2>
                        <div className="book-placeholder" onClick={() => handleBookClick('https://heyzine.com/flip-book/22b5f71c6c.html')}>
                            <img
                                className='bg-img-cover'
                                src="https://img.freepik.com/free-photo/indian-republic-day-celebration-digital-art-with-people_23-2151070643.jpg?t=st=1728912414~exp=1728916014~hmac=56d80288d41d709ece8bd599b4e0a733f82d172964cba1e16de16c174b31cb6f&w=1380"
                                alt="Manifesto Cover"
                            />
                        </div>
                        <div className="social">
                            <a href="https://www.facebook.com/SambhajiPatilBJP" target='_blank' aria-label="Facebook"><FaFacebookF /></a>
                            <a href="https://x.com/sambhajipatil77" target='_blank' aria-label="Twitter"><FaXTwitter /></a>
                            <a href="https://www.youtube.com/SambhajiPatilNilangekar77" target='_blank' aria-label="Youtube"><IoLogoYoutube /></a>
                            <a href="https://www.instagram.com/sambhajipatil77/-" target='_blank' aria-label="Instagram"><AiFillInstagram /></a>
                        </div>
                        <button className="btn" onClick={() => handleDownload(pdfUrl)}>{data.m_download}</button>
                    </div>

                    <div className="card mright">
                        <h2>{data.m_marathi}</h2>
                        <div className="book-placeholder" onClick={() => handleBookClick('https://heyzine.com/flip-book/22b5f71c6c.html')}>
                            <img
                                className='bg-img-cover'
                                src="https://img.freepik.com/free-photo/indian-republic-day-celebration-digital-art-with-people_23-2151070643.jpg?t=st=1728912414~exp=1728916014~hmac=56d80288d41d709ece8bd599b4e0a733f82d172964cba1e16de16c174b31cb6f&w=1380"
                                alt="Manifesto Cover"
                            />
                        </div>
                        <div className="social">
                            <a href="https://www.facebook.com/SambhajiPatilBJP" target='_blank' aria-label="Facebook"><FaFacebookF /></a>
                            <a href="https://x.com/sambhajipatil77" target='_blank' aria-label="Twitter"><FaXTwitter /></a>
                            <a href="https://www.youtube.com/SambhajiPatilNilangekar77" target='_blank' aria-label="Youtube"><IoLogoYoutube /></a>
                            <a href="https://www.instagram.com/sambhajipatil77/-" target='_blank' aria-label="Instagram"><AiFillInstagram /></a>
                        </div>
                        <button className="btn" onClick={() => handleDownload(pdfUrl)}>{data.m_download}</button>
                    </div>
                </div>
            </div>

            {/* Iframe for Flipbook */}
            {isIframeOpen && (
                <div className="iframe-overlay">
                    <button className="close-button" onClick={closeIframe}>{data.m_close}</button>
                    <iframe
                        src={selectedBook}
                        width="100%"
                        height="80%"
                        title="Flipbook"
                    />
                </div>
            )}
        </div>
        </>
        );
      })}
        </>
    );
}

export default Manifesto;
